import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import { getParagraph } from "../components/paragraphs/paragraphHelpers";
import Seo from "../components/layout/seo"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ArticlePost = ({ data }) => {
  const paragraphs = data.page.relationships.paragraphs.map(getParagraph);
  return (
    <Layout>
      <Seo title={data.page.title} />
      <hr />

      <div className="lg:flex lg:space-x-32 bg-yellow container mx-auto py-16">


        <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-2xl">
          <h1 className="text-2xl font-bold">{data.page.title}</h1>
          <div className="text-sm mb-4">{data.page.created}</div>
          <div className="paragraphs">
            {paragraphs}
          </div>
        </div>

        <div className="lg:w-1/5 prose border-l border-dotted pl-16">
          <div className="text-3xl mb-8 border-b-4 border-primary pb-4 font-semibold">Other reads</div>
          <div className="items">
            {data.other.edges.map((item, key) => {
              return (
                <Link to={item.node.path.alias} className="text-sm mb-6 block" activeStyle={{ color: "#ccc" }} key={key}>{item.node.title}</Link>
              )
            })}
          </div>
        </div>


      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    page: nodeArticle(
      id: { eq: $id }
      ) 
    {
      title
      field_teaser {
        value
      }
      created(formatString: "DD MMMM, YYYY")
      relationships {
        paragraphs: field_content_builder {
          type: __typename
          ...ParagraphContentBasic
          ...ParagraphImage
          ...ParagraphContentQuote
          ...ParagraphContentImage
          ...ParagraphParallax
          ...ParagraphVideo
        }
        field_image {
          relationships {
            field_media_image_1 {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 1200)
                }
              }
            }
          }
        }
      }
    },
    other: allNodeArticle(
      filter: {relationships: {field_article_destination: {elemMatch: {name: {eq: "Solar PV"}}}}}
      sort: { fields: [created], order: DESC}
      limit: 5
    ){
    edges {
      node {
        id
        drupal_internal__nid
        title
        field_teaser {
          value
        }
        created(formatString: "DD MMMM, YYYY")
        status
        path {
          alias
        }
        
      }
    }
  }
  }
`

export default ArticlePost